.team-container {
    background-color: #f2f2f2;
    height: 100%;
    margin: auto;
    padding: 0 20px;
}

.team {
    padding: 50px;
    display: flex;
    justify-content: center;
    gap: 2rem;
    flex-wrap: wrap;
}

.team-p {
    font-size: 1rem;
    margin-bottom: 20px;
    width: 50%;
    transform: translateX(-50%);
    text-align: center;
}

.team-pb {
    font-size: 0.9rem;
    margin-bottom: 20px;
    width: 100%;
    text-align: center;
}

.team-member {
    flex: 0 1 300px;
    max-width: 300px;
    text-align: center;
}

.team-member img {
    width: 100%;
    height: auto;
    border-radius: 50%;
    object-fit: cover;
}

.team-category {
    margin-bottom: 2rem;
}

.category-title {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
}

@media (max-width: 768px) {
    .team {
        flex-direction: column;
        gap: 1.5rem;
    }

    .team-p, .team-pb {
        width: 100%;
        transform: none;
    }
}
