.partners-container {
    width: 100%;
    overflow: hidden;
    background-color: #f2f2f2; /* You can change the background color */
    padding: 20px 0;
    text-align: center; /* Centers the title */
  }
  
  .partners-title {
    font-size: 1.5rem; /* Adjust the font size for the title */
    font-weight: bold;
    color: #333; /* Set the title color */
    margin-bottom: 20px; /* Space between title and logos */
  }
  
  .partners-logos {
    display: flex;
    align-items: center;
    justify-content: center; /* Center logos horizontally */
    gap: 50px; /* Space between logos */
  }
  
  .partner-logo {
    opacity: 0.7;
    transition: opacity 0.3s ease-in-out;
  }
  
  .partner-logo:hover {
    opacity: 1; /* Make logo brighter on hover */
  }
  
  .partner-logo img {
    max-height: 50px; /* Adjust based on your desired logo size */
    object-fit: contain;
  }
  
  /* Media query for smaller screens (adjust max-width as needed) */
  @media (max-width: 768px) {
    .partners-logos {
      flex-direction: column; /* Stack logos vertically on small screens */
      align-items: center; /* Center logos horizontally */
      white-space: normal; /* Allow logos to wrap */
    }
  
    .partner-logo {
      margin-bottom: 20px; /* Space between stacked logos */
    }
  
    .partners-title {
      font-size: 1.2rem; /* Slightly smaller title font on smaller screens */
    }
  }
  