.mission-container {
    display: flex;
    height: 100vh;
    background-color: #f2f2f2;
    flex-direction: row; /* Default layout: columns */
  }
  
  .accordion {
    display: flex;
    width: 75%; /* Adjust width as necessary */
    cursor: pointer;
    flex-direction: row; /* Default layout: columns */
  }
  
  .accordion-item {
    flex: 1;
    display: flex;
    flex-direction: column;
    transition: flex 0.3s ease;
    color: white;
    overflow: hidden;
    position: relative;
  }
  
  .accordion-item.active {
    flex: 4;
  }
  
  .accordion-header {
    padding: 20px;
    cursor: pointer;
    position: relative;
  }
  
  .accordion-number {
    font-size: 3rem;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .accordion-header-content {
    display: flex;
    flex-direction: column;
  }
  
  .accordion-title {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .accordion-header .plus-sign {
    font-size: 2rem;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: -20px;
    cursor: pointer;
  }
  
  .accordion-content {
    padding: 20px;
    display: none;
  }
  
  .accordion-item.active .accordion-content {
    display: block;
  }
  
  .fixed-image-container {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    background-color: #f0f0f0;
    overflow: hidden;
  }
  
  .fixed-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 60%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
  }
  
  .fixed-image-container::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(5, 204, 152, 1);
    mix-blend-mode: multiply;
    z-index: 1;
  }
  
  @media (max-width: 1000px) {
    .mission-container {
      flex-direction: column; /* Stack vertically on small screens */
    }
  
    .accordion {
      flex-direction: column; /* Stack items vertically on small screens */
      width: 100%; /* Full width on small screens */
    }
  
    .accordion-item {
      flex: none; /* Remove flex-grow for stacked rows */
      width: 100%; /* Full width on small screens */
    }
  }
  