/* General Styles */
.refund-policy {
    font-family: 'Arial', sans-serif;
    color: #333;
    line-height: 1.6;
    padding: 20px;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 8px;
    max-width: 800px;
    margin: 20px auto;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-top: 100px;
}

/* Header */
.refund-policy h1 {
    font-size: 2rem;
    color: #004d40;
    text-align: center;
    margin-bottom: 20px;
    text-transform: uppercase;
    border-bottom: 2px solid #ddd;
    padding-bottom: 10px;
}

/* Section Headers */
.refund-policy h2 {
    font-size: 1.5rem;
    color: #00796b;
    margin-top: 20px;
    margin-bottom: 10px;
    border-bottom: 1px solid #ddd;
    padding-bottom: 5px;
}

/* Subsections */
.refund-policy h3 {
    font-size: 1.2rem;
    color: #009688;
    margin-top: 15px;
    margin-bottom: 8px;
}

/* Paragraphs */
.refund-policy p {
    margin-bottom: 10px;
    text-align: justify;
}

/* List Styles */
.refund-policy ul {
    list-style-type: disc;
    margin: 10px 0 20px 20px;
    padding: 0;
}

.refund-policy li {
    margin-bottom: 8px;
}

/* Highlighted Text */
.refund-policy strong {
    color: #00796b;
}

/* Footer Note */
.refund-policy .footer-note {
    font-size: 0.9rem;
    color: #555;
    margin-top: 20px;
    text-align: center;
    border-top: 1px solid #ddd;
    padding-top: 10px;
}
